export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1024;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

//login
export const LOGIN = "/login";
export const GET_COUNTRIES = "/countries";
export const GIFT_PURCHASE = "/gift_purchase";
export const GET_SUBSRIPTION_TYPES = "/subscription_types/";
export const VERIFY_ACTIVATION_CODE = "/gifts/verify_activation_code";
export const ACTIVATE_SUBSCRIPTION = "/gifts/activation";
export const GIFTS = "/gifts";
export const SUBSCRIBE = "subscriptions_signup_web/signup";
export const GET_ACCOUNT = "/accounts/";
export const UPDATE_ACCOUNT = "/accounts/";
export const CHANGE_PASSWORD = "/passwords/";
export const DELETE_ACCOUNT = "/accounts/";
export const GET_SUBSCRIPTIONS = "/subscriptions/";
export const UPDATE_SUBSCRIPTION = "/subscriptions/";
export const DELETE_SUBSCRIPTION = "/subscriptions/";
export const GET_INVOICES = "/invoices/";
export const GET_INVOICES_DETAILS = "/invoices/";
export const PAY_NOW = "";
export const CHECK_IF_EMAIL_EXISTS = "/gift_purchase/check_email_exist/";
export const GET_PAYMENT_METHODS = "/payment_methods/";
export const ADD_CARD = "/payment_methods/add_card/";
export const MAKE_CARD_DEFAULT = "/payment_methods/make_default/";
export const DELETE_CARD = "/payment_methods/";
export const GET_DASHBOARD = "/dashboard/";
export const REQUEST_PASSWORD_RESET = "/reset_password_request/";
export const CHANGE_PASSWORD_ON_LOGIN = "/passwords/password_reset_on_login/";
export const ADD_SUBSCRIPTION = "/subscriptions/";
export const MANUAL_PAYMENT = "/payments/manual_payment";
export const GET_PROMO_DESCRIPTION = "/promo_codes/";
export const CHECK_VALID_PROMO_CODE = "/promo_codes/check_if_valid_promo_code";
export const GET_ALERTS_DATA = "/control_panel/";
export const UPDATE_ALERTS_DATA = "/control_panel/";
export const GET_ADDRESS_AND_TIMEZONE = "/control_panel/timezone_address";
export const UPDATE_ALERT_LOCATION = "/control_panel/update_alert_location/";
export const GET_CURRENT_PLAN = "/upgrade_plan/get_current_customer_plan/";
export const UPGRADE_PLAN = "/upgrade_plan";
export const GET_SUBSCRIPTION_REACTIVATION_DETAILS = "/subscriptions/reactivation_request/";
export const REACTIVATE_SUBSCRIPTION = "/subscriptions/reactivate/";

//admin endpoints
export const ADMIN_LOGIN = "/login/send_login_code";
export const GET_ADMIN_USER = "/users/";
export const CHANGE_ADMIN_PASSWORD = "/users/update_password/";
export const UPDATE_ADMIN_USER = "/users/";
export const VERIFY_LOGIN_CODE = "/login/verify_login_code";